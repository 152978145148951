<template>
  <div class="ground-air-information-widget">
    <div class="ground-air-information-title">
      <h2>{{ $t('tableTitles.GroundAirInfo') }}</h2>
    </div>
    <div class="ground-air-information-widget-container">
      <groundAirDataTableOverview
        :enableCreateTableRowAction="enableCreateTableRowAction"
        :enableDeleteTableRowAction="enableDeleteTableRowAction"
        :enableUpdateTableRowAction="enableUpdateTableRowAction"
        :enableColumnChooser="enableColumnChooser"
        :searchPanelEnabled="searchPanelEnabled"
        :enableExportSettings="enableExportSettings"
      />
    </div>
  </div>
</template>
<script>
import groundAirDataTableOverview from '@/components/table-overviews/groundAirTableOverview'
export default {
  name: 'groundAirInfoWidget',
  components: {
    groundAirDataTableOverview
  },
  data() {
    return {
      enableCreateTableRowAction: false,
      enableDeleteTableRowAction: false,
      enableUpdateTableRowAction: false,
      enableColumnChooser: false,
      searchPanelEnabled: false,
      enableExportSettings: false
    }
  }
}
</script>
<style lang="less" scoped>
.ground-air-information-widget {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .ground-air-information-title h2 {
    display: flex;
    justify-content: center;
    margin: 0 0 15px 0;
    font-size: 24px;
    padding-bottom: 15px;
    color: #89b368;
    border-bottom: 1px solid #d5d5d5;
  }
  .ground-air-information-widget-container {
    display: flex;
    flex-direction: column;
    height: 85%;
    width: 100%;
  }
}
</style>
