export default {
  computed: {
    gridStackId() {
      return `grid-stack-container-${this.currentDashboardId}`
    },
    projectId() {
      return this.$route.params.projectId.toString()
    },
    currentDashboardId() {
      return this.$route.params.dashboardId
    },
    getCurrentProjectCode() {
      return this.$store.getters.getCurrentProject.PrCode
    }
  }
}
