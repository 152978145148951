<template>
  <div class="dashboard-header">
    <div class="dashboard-header-buttons">
      <div v-if="!isEditing" class="dashboard-header-buttons-not-editing">
        <button class="button-hover" @click="$emit('editMode')">
          <i class="glyphicons glyphicons-pencil"></i>
          <p>{{ $t('dashboardHeader.Edit') }}</p>
        </button>
        <button class="button-hover" @click="$emit('refresh')">
          <i class="glyphicons glyphicons-repeat"></i>
          <p>{{ $t('dashboardHeader.Refresh') }}</p>
        </button>
      </div>
      <div v-else class="dashboard-header-buttons-while-editing">
        <div class="buttons-left">
          <button class="button-hover" @click="$emit('editMode')">
            <i class="glyphicons glyphicons-ok"></i>
            <p>{{ $t('dashboardHeader.Done') }}</p>
          </button>
          <div class="dashboard-dropdown">
            <button ref="dropdownMenu" class="button-hover" :title="$t('dashboardHeader.DropdownMenu.Title')" @click="showDropdown = !showDropdown">
              <i class="glyphicons glyphicons-option-horizontal"></i>
            </button>
          </div>
          <div v-show="showDropdown" class="dropdown-content">
            <div class="button-auto-align">
              <span class="align-text">{{ $t('dashboardHeader.AutoAlign') }}:</span>
              <DxSwitch v-model="switchAutoAligning" />
            </div>
            <button @click="$emit('renameDashboard')">
              <span>{{ $t('dashboardHeader.rename') }}</span>
              <i class="glyphicons glyphicons-pencil"></i>
            </button>
            <button v-show="widgets.length > 0" @click="$emit('deleteAllWidgets')">
              <span>{{ $t('dashboardHeader.DeleteWidgets') }}</span>
              <i class="glyphicon glyphicon-trash"></i>
            </button>
          </div>
        </div>
        <div class="text-middle">
          <span>{{ $t('dashboardHeader.DoneMessage') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DxSwitch } from 'devextreme-vue/switch'
export default {
  name: 'dashboardHeader',
  components: {
    DxSwitch
  },
  props: {
    isEditing: Boolean,
    isAutoAligning: Boolean,
    currentDashboard: Object,
    widgets: []
  },
  data() {
    return {
      switchAutoAligning: false,
      showDropdown: false
    }
  },
  mounted() {
    document.addEventListener('click', this.onClickOutsideDropdown)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onClickOutsideDropdown)
  },
  watch: {
    currentDashboard: {
      handler: function (dashboard) {
        if (dashboard.float) {
          this.switchAutoAligning = false
        } else {
          this.switchAutoAligning = true
        }
      },
      immediate: true
    },
    isAutoAligning: {
      handler: function (value) {
        this.switchAutoAligning = value
      },
      immediate: true
    },
    switchAutoAligning: {
      handler: function (value) {
        this.$emit('switchAutoAlign', value)
      }
    }
  },
  methods: {
    onClickOutsideDropdown(event) {
      const dropdownMenu = this.$refs.dropdownMenu

      if (dropdownMenu && dropdownMenu.contains(event.target)) {
        return
      }
      this.showDropdown = false
    }
  }
}
</script>

<style lang="less" scoped>
.dashboard-header {
  display: flex;
  flex-direction: row;
  color: #111;
  background: lightgray;
  border-bottom: 1px solid rgba(104, 88, 88, 0.5);
  height: 35px;
  p {
    display: flex;
    margin: 0;
  }
}
.dashboard-header-buttons {
  display: flex;
  height: 100%;
  width: 100%;
  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
  }
  p {
    font-weight: 700;
  }
  .dashboard-header-buttons-not-editing {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .dashboard-header-buttons-while-editing {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    .buttons-left {
      display: flex;
      align-items: center;
      .button-auto-align {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        height: 100%;
        .align-text {
          padding-right: 5px;
        }
      }
    }
    .text-middle {
      display: flex;
      align-items: center;
      margin: auto;
    }
  }
  .dropdown-content {
    background-color: #ffffff;
    height: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    margin-top: 8rem;
    margin-left: 12rem;
    padding: 0.5rem;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgb(184, 182, 182);
    button {
      &:hover {
        background-color: rgba(241, 232, 232, 0.966);
      }
    }
  }
  .button-hover {
    transition: 0.25s;

    &:hover {
      transform: scale(0.9);
    }
  }
}
.dashboard-dropdown {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}
</style>
