<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="dynamic-chart">
    <div class="graph-toolset">
      <tiDialog ref="widgetPopup">
        <settingsWaterSamplesWidget :allWaterSamples="allWaterSamples" :graphType="graphType" :widgetData="widgetData" />
      </tiDialog>
    </div>
    <DxChart v-if="graphSampleData && graphSampleData.length > 0" id="waterSampleGraph" name="waterSamplesDynamicGraph" :data-source="graphSampleData">
      <DxTitle :text="graphTitle" />
      <DxCommonSeriesSettings :type="getGraphType" argument-field="graphLabel" hover-mode="allArgumentPoints" selection-mode="allArgumentPoints" color="#337ab7">
        <DxLabel :visible="true">
          <DxFormat :precision="0" type="fixedPoint" />
        </DxLabel>
      </DxCommonSeriesSettings>
      <DxSeries value-field="totalSamples" :name="$t('label.WaterSamples')" />
      <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
      <DxExport :enabled="true" />
      <NvLoading :show="isLoading" />
    </DxChart>
  </div>
</template>

<script scoped>
import { DxChart, DxSeries, DxCommonSeriesSettings, DxLabel, DxFormat, DxExport, DxLegend, DxTitle } from 'devextreme-vue/chart'
import settingsWaterSamplesWidget from '@/components/dashboards/Widgets/widgetSettings/settingsWaterSamplesWidget'

import deDevExtremeLocale from '@/locales/devExtreme/de'
import esDevExtremeLocale from '@/locales/devExtreme/es'
import frDevExtremeLocale from '@/locales/devExtreme/fr'
import itDevExtremeLocale from '@/locales/devExtreme/it'
import nlDevExtremeLocale from '@/locales/devExtreme/nl'
import { locale, loadMessages } from 'devextreme/localization'

import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import NvLoading from '@/components/_shared/loading'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import tiDialog from '@/components/_shared/tiDialog'
import { bus } from '@/bus'
import { wantsDashboardData } from '@/components/dashboards/mixins'

const FilterSamplesPerMonth = 'SamplesPerMonth'
const FilterSamplesPerYear = 'SamplesPerYear'
const FilterSamplesCustomYear = 'CustomYear'
const TiPopup = Popup[Config.platform].default

export default {
  name: 'waterSamplesWidget',
  mixins: [wantsDashboardData],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    widgetData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxExport,
    DxLegend,
    DxTitle,
    NvLoading,
    settingsWaterSamplesWidget,
    tiDialog
  },
  mounted() {
    this.emitRegisterInterface()
    this.emitWidgetIsCustomizable()
  },
  computed: {
    language() {
      return Cookie.get('language')
    },
    widgetPopup() {
      return this.$refs.widgetPopup
    },
    getStorageGraphSettings() {
      return this.widgetData
    },
    getGraphType() {
      return this.graphType.type
    }
  },
  data() {
    return {
      widgetIsCustomizable: true,
      allWaterSamples: [],
      graphSampleData: [],
      graphType: [],
      samplesByMonth: [
        { graphLabel: this.$t('months.jan'), monthId: 0 },
        { graphLabel: this.$t('months.feb'), monthId: 1 },
        { graphLabel: this.$t('months.mar'), monthId: 2 },
        { graphLabel: this.$t('months.apr'), monthId: 3 },
        { graphLabel: this.$t('months.may'), monthId: 4 },
        { graphLabel: this.$t('months.jun'), monthId: 5 },
        { graphLabel: this.$t('months.jul'), monthId: 6 },
        { graphLabel: this.$t('months.aug'), monthId: 7 },
        { graphLabel: this.$t('months.sep'), monthId: 8 },
        { graphLabel: this.$t('months.oct'), monthId: 9 },
        { graphLabel: this.$t('months.nov'), monthId: 10 },
        { graphLabel: this.$t('months.dec'), monthId: 11 }
      ]
    }
  },
  methods: {
    async getGraphData() {
      try {
        return this.$store
          .dispatch('getAllWaterSamples', {
            projectId: this.projectId
          })
          .then((response) => {
            return this.handleGetTableDataResponse(response)
          })
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    groupSamplesByMonth(customYearFilter) {
      const filterYear = customYearFilter || new Date().getUTCFullYear()
      const samplesByMonth = this.samplesByMonth.map((sampleByMonth) => {
        sampleByMonth.totalSamples = 0
        return sampleByMonth
      })

      const waterSamples = this.allWaterSamples
      waterSamples.forEach((waterSample) => {
        if (!waterSample.WsDate && !waterSample.FtId) return

        const sampleDate = new Date(waterSample.WsDate)
        samplesByMonth.forEach((monthSample) => {
          if (monthSample.monthId === sampleDate.getMonth() && filterYear.toString() === sampleDate.getUTCFullYear().toString()) {
            monthSample.totalSamples++
          }
        })
      })
      return samplesByMonth
    },
    groupSamplesByYear() {
      const samplesByYear = []
      const currentYear = new Date().getUTCFullYear()
      for (let i = 2000; i <= currentYear; i++) samplesByYear.push({ graphLabel: i.toString(), totalSamples: 0 })
      for (const sample of this.allWaterSamples) {
        if (!sample.WsDate && !sample.FtId) continue

        const sampleCreationYear = new Date(sample.WsDate).getUTCFullYear()
        samplesByYear.forEach((sampleByYear) => {
          if (sampleByYear.graphLabel === sampleCreationYear.toString()) {
            sampleByYear.totalSamples++
          }
        })
      }

      return samplesByYear
    },
    setStorageGraphSettings(data) {
      const currentStorageData = this.getStorageGraphSettings
      const newStorageData = currentStorageData
      newStorageData.additionalSettings = this.mapNewGraphAdditionalSettings(currentStorageData.additionalSettings, data)
    },
    mapNewGraphAdditionalSettings(setting, newSettings) {
      if (newSettings.graphType) {
        setting.graphType = newSettings.graphType
      }
      if (newSettings.graphFilter) {
        setting.graphFilter = newSettings.graphFilter
      }
      return setting
    },
    applyGraphFilter(filteringType, filterValue = null) {
      switch (filteringType) {
        case 'SamplesPerMonth':
          this.setStorageGraphSettings({ graphFilter: { filterType: FilterSamplesPerMonth, value: filterValue } })
          this.graphSampleData = this.groupSamplesByMonth()
          this.graphFilterSelectedValue = FilterSamplesPerMonth
          this.graphTitle = this.$t('graphTitles.waterSamplesPerMonth', { 0: filterValue })
          break

        case 'SamplesPerYear':
          this.setStorageGraphSettings({ graphFilter: { filterType: FilterSamplesPerYear, value: filterValue } })
          this.graphSampleData = this.groupSamplesByYear()
          this.graphFilterSelectedValue = FilterSamplesPerYear
          this.graphTitle = this.$t('graphTitles.waterSamplesPerYear')
          break

        case 'CustomYear':
          this.setStorageGraphSettings({ graphFilter: { filterType: FilterSamplesCustomYear, value: filterValue } })
          this.graphSampleData = this.groupSamplesByMonth(filterValue)
          this.graphFilterSelectedValue = filterValue
          this.graphTitle = this.$t('graphTitles.waterSamplesPerMonth', { 0: filterValue })
          break

        default:
          const year = new Date().getUTCFullYear()
          this.setStorageGraphSettings({ graphFilter: { filterType: FilterSamplesPerMonth, value: year } })
          this.graphSampleData = this.groupSamplesByMonth()
          this.graphFilterSelectedValue = FilterSamplesPerMonth
          this.graphTitle = this.$t('graphTitles.waterSamplesPerMonth', { 0: year })
      }
    },
    handleGetTableDataResponse(data) {
      const GraphFilterSettings = this.getStorageGraphSettings.additionalSettings
      this.allWaterSamples = data.tblWaterSamples
      this.applyGraphFilter(GraphFilterSettings.graphFilter.filterType, GraphFilterSettings.graphFilter.value)
      this.filteredWaterSamples = []
      this.graphType = GraphFilterSettings.graphType
    },
    handleErrorResponse(errorMessage = 'message.UnknownError') {
      TiPopup.popup(this.$t(errorMessage))
    },
    emitRegisterInterface() {
      this.$emit('registerWidgetInterface', {
        refresh: () => this.refresh()
      })
    },
    refresh() {
      this.getGraphData()
    },
    async showWidgetPopup() {
      let popupVisibility = true
      let widgetSettings = await this.widgetPopup.open(
        {
          title: this.$t('dashboardWidgetPopup.Configuration'),
          buttonLabel: this.$t('dashboardWidgetPopup.Save')
        },
        this.emitToggleWidgetMovement(popupVisibility)
      )
      popupVisibility = false
      this.emitToggleWidgetMovement(popupVisibility)
      if (widgetSettings) {
        this.graphType = widgetSettings.graphType
        switch (widgetSettings.graphFilter.filterType) {
          case 'CustomYear':
            this.applyGraphFilter(FilterSamplesCustomYear, widgetSettings.graphFilter.value)
            break

          case 'SamplesPerMonth':
            this.applyGraphFilter(FilterSamplesPerMonth, new Date().getUTCFullYear())
            break

          case 'SamplesPerYear':
            this.applyGraphFilter(FilterSamplesPerYear)
            break

          default:
            this.applyGraphFilter(FilterSamplesCustomYear, widgetSettings.graphFilter.value)
        }
        switch (widgetSettings.graphType.type) {
          case 'bar':
            const barType = 'bar'
            this.setStorageGraphSettings({ graphType: { type: barType } })
            break

          case 'line':
            const lineType = 'line'
            this.setStorageGraphSettings({ graphType: { type: lineType } })
            break

          default:
            this.setStorageGraphSettings({ graphType: { type: barType } })
        }
        this.$emit('saveWidgetSettings')
      }
    },
    emitToggleWidgetMovement(popupVisibility) {
      return bus.$emit('toggleWidgetMovement', popupVisibility)
    },
    emitWidgetIsCustomizable() {
      this.$emit('widgetIsCustomizable', this.widgetIsCustomizable)
    }
  },
  watch: {
    projectId: {
      handler: async function () {
        await this.getGraphData()
      },
      immediate: true
    },
    language: {
      handler: function () {
        if (this.language) {
          switch (this.language && this.language !== 'en') {
            case 'nl':
              loadMessages(nlDevExtremeLocale)
              break
            case 'fr':
              loadMessages(frDevExtremeLocale)
              break
            case 'de':
              loadMessages(deDevExtremeLocale)
              break
            case 'es':
              loadMessages(esDevExtremeLocale)
              break
            case 'it':
              loadMessages(itDevExtremeLocale)
              break

            default:
              return // Use of return here since DxChart already uses the English local messages
          }
          locale(this.language)
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="less">
.select-data-type-field {
  top: 0;
  left: 24px;
  z-index: 9;
  width: 100%;
  max-width: 280px;

  span {
    font-weight: 600;
  }

  .select-data-type {
    border: none;
    background: #67ac45;
    color: white;
    padding: 5px;
    border-radius: 3px;
    display: block;
    outline: none;
  }
}

.dynamic-chart {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.graph-type-select {
  top: 0;
  left: 24px;
  z-index: 9;
  width: 100%;
  max-width: 280px;

  span {
    font-weight: 600;
  }

  .select-data-type {
    border: none;
    background: #67ac45;
    color: white;
    padding: 5px;
    border-radius: 3px;
    display: block;
    outline: none;
  }
}

.graph-toolset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
</style>
