<template>
  <div class="enter-dashboard-name-field">
    <p>{{ $t('addDashboardPopup.subTitle') }}</p>
    <input v-model="newDashboardName" required type="text" maxlength="20" display-expr="name" value-expr="value" @input="capitalizeFirstLetter" />
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>
<script>
import stringUtils from '@/utils/stringUtils'
export default {
  inject: ['validate', 'invalidate', 'agree', 'cancel'],
  props: {
    dashboards: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newDashboardName: '',
      errorMessage: ''
    }
  },
  watch: {
    newDashboardName: {
      handler: function (value) {
        let valid = this.validateDashboardName(value)
        if (valid) {
          this.validate({ name: value, class: 'blue', float: true, dashboardWidgets: [] })
        } else {
          this.invalidate()
        }
      },
      immediate: true
    }
  },
  methods: {
    validateDashboardName(value) {
      const dashboardExist = this.dashboards.some((dashboard) => dashboard.name.trim().toLowerCase() === value.trim().toLowerCase())
      if (dashboardExist) {
        this.errorMessage = this.$t('addDashboardPopup.errorMessage')
        return false
      } else if (value.length > 0) {
        this.errorMessage = ''
        return true
      } else {
        this.errorMessage = ''
        return false
      }
    },
    capitalizeFirstLetter() {
      this.newDashboardName = stringUtils.capitalizeFirstLetter(this.newDashboardName)
    }
  }
}
</script>

<style lang="less" scoped>
.enter-dashboard-name-field p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 120%;
}

.error-message {
  font-size: 110%;
  color: red;
  margin-top: 5px;
}
</style>
