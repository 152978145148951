<template>
  <div class="dashboard">
    <app-header
      :tile-view-route="{
        name: 'project',
        params: { projectId: currentProjectId.toString() }
      }"
      :table-view-route="{
        name: 'overview',
        params: { projectId: currentProjectId.toString(), overviewId: '108' }
      }"
    />
    <aside class="side-navigator">
      <div class="aside-header">
        <h3>{{ $t('pages.dashboards') }}</h3>
        <button v-if="currentDashboardExists" v-show="!isEditing" class="create-button" @click="toggleAddDashboardPopup">
          <span class="glyphicons glyphicons-plus"></span>
        </button>
        <button v-if="currentDashboardExists" v-show="!isEditing" class="delete-button" @click="toggleDeleteDashboardPopup">
          <span class="glyphicon glyphicon-trash"></span>
        </button>
      </div>
      <div class="buttons">
        <router-link
          v-for="dashboard in dashboards"
          :key="dashboard.id"
          :to="{ name: 'dashboard', params: { dashboardId: dashboard.id.toString() } }"
          :class="'dashboard-button ' + dashboard.class"
          active-class="active"
        >
          {{ dashboard.name }}
        </router-link>
      </div>
    </aside>
    <div class="dashboard-content">
      <template v-if="currentDashboardExists">
        <DashboardHeader
          :isEditing="isEditing"
          :currentDashboard="currentDashboard"
          :isAutoAligning="isAutoAligning"
          :widgets="widgets"
          @editMode="toggleEdit"
          @refresh="refreshWidgets"
          @renameDashboard="toggleRenameDashboardPopup"
          @switchAutoAlign="switchAutoAlign"
          @deleteAllWidgets="toggleDeleteAllWidgetsPopup"
        />
      </template>
      <ti-dialog ref="addDashboardPopup">
        <addDashboard :dashboards="dashboards" />
      </ti-dialog>
      <ti-dialog ref="deleteDashboardPopup">
        <deleteDashboard :dashboards="dashboards" />
      </ti-dialog>
      <ti-dialog ref="renameDashboardPopup">
        <renameDashboard :dashboards="dashboards" />
      </ti-dialog>
      <ti-dialog ref="deleteAllWidgetsPopup">
        <deleteAllWidgets :widgets="widgets"/>
      </ti-dialog>
      <div :class="isEditing ? 'dashboard-grid grid-editing' : 'dashboard-grid'">
        <template v-if="currentDashboardExists">
          <div :id="currentGridStackContainerId">
            <div v-show="isEditing" class="dashboard-grid-background" />
            <template v-if="widgets.length > 0 || isEditing">
              <Widget
                v-for="widget in widgets"
                :key="widget.id"
                :widgetData="widget"
                :is-editing="isEditing"
                @saveWidgetSettings="saveWidgets"
                @delete="deleteWidget"
                @registerWidgetInterface="registerWidgetInterface"
                @unregisterWidgetInterface="unregisterWidgetInterface"
              />
              <div v-show="isEditing">
                <WidgetMenu :is-editing="isEditing" :widgets="widgets" :disabledWidgetType="disableMapWidget.type" @addWidget="addWidget" />
              </div>
            </template>
            <template v-else>
              <div class="dashboard-defaultMessage">
                <h1>{{ $t('label.dashboardDefaultName') }}</h1>
                <button @click="toggleEdit">{{ $t('label.dashboardButton') }}</button>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <Dashboard404 />
        </template>
      </div>
    </div>
    <app-footer />
  </div>
</template>

<script>
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import Dashboard404 from '@/components/dashboards/Dashboard404'
import DashboardHeader from '@/components/dashboards/DashboardHeader'
import WidgetMenu from '@/components/dashboards/WidgetMenu'
import Widget from '@/components/dashboards/Widget'
import guidUtils from '@/utils/guidUtils'
import TiDialog from '@/components/_shared/tiDialog'
import addDashboard from '@/components/dashboards/modals/addDashboard'
import deleteDashboard from '@/components/dashboards/modals/deleteDashboard'
import renameDashboard from '@/components/dashboards/modals/renameDashboard'
import deleteAllWidgets from '@/components/dashboards/modals/deleteAllWidgets'
import 'gridstack/dist/gridstack.min.css'
import 'gridstack/dist/gridstack-extra.min.css'
import { GridStack } from 'gridstack'
import { bus } from '@/bus.js'

export default {
  components: {
    AppHeader,
    AppFooter,
    Dashboard404,
    WidgetMenu,
    Widget,
    DashboardHeader,
    TiDialog,
    addDashboard,
    deleteDashboard,
    renameDashboard,
    deleteAllWidgets
  },
  data() {
    return {
      grid: null,
      isCustomizingWidget: false,
      widgets: [],
      isEditing: false,
      isAutoAligning: true,
      defaultDashboard: {
        id: '1',
        name: this.$t('defaultDashboard.name'),
        class: 'green',
        float: false,
        dashboardWidgets: []
      },
      dashboards: [],
      widgetInterfaces: [],
      disableMapWidget: [
        {
          type: ''
        }
      ]
    }
  },
  watch: {
    currentDashboardId: {
      handler: function () {
        setTimeout(() => {
          if (this.currentDashboardExists) {
            this.initGridStack()
            this.grid.removeAll()
            this.loadWidgets()
            this.toggleGridOnWidgetPopup()
          }
        }, 500)
      },
      immediate: true
    }
  },
  async created() {
    this.$store
      .dispatch('fetchProject', {
        PrID: this.currentProjectId
      })
      .then(() => {
        this.setPageTitle()
      })
  },
  async mounted() {
    this.dashboards = await this.getDashboardsFromService()
    if (this.dashboards.length === 0) {
      this.createNewDashboard(this.defaultDashboard.id, this.defaultDashboard)
    }
  },
  methods: {
    initGridStack() {
      this.grid = GridStack.init(
        {
          column: 12,
          cellHeight: 100,
          margin: 5,
          disableResize: true,
          disableDrag: true,
          float: false
        },
        `#${this.currentGridStackContainerId}`
      )
    },
    async loadWidgets() {
      this.widgetInterfaces = []
      this.widgets = []
      const response = await this.$store.dispatch('getSettingByName', {
        settingName: 'dashboards'
      })
      this.dashboards = JSON.parse(response.data.SettingValue)
      this.currentDashboard.dashboardWidgets.forEach((widget) => {
        this.addWidgetToGridAndForceUpdate(widget)
      })
    },
    async addWidgetToGridAndForceUpdate(widget) {
      this.widgets.push(widget)
      await this.$nextTick()
      this.grid.makeWidget(`#${widget.id}`)
    },
    async addWidget(widgetData) {
      const additionalSettings = widgetData.additionalSettings
      if (additionalSettings) {
        const widget = {
          id: `widget-${guidUtils.newGuid()}`,
          type: widgetData.type,
          name: widgetData.name,
          location: {
            xAxis: widgetData.location.xAxis || 0,
            yAxis: widgetData.location.yAxis || 0
          },
          size: {
            width: widgetData.size.width,
            minimumWidth: widgetData.size.minimumWidth,
            maximumWidth: widgetData.size.maximumWidth,
            height: widgetData.size.height,
            minimumHeight: widgetData.size.minimumHeight,
            maximumHeight: widgetData.size.maximumHeight
          },
          additionalSettings: widgetData.additionalSettings
        }
        this.addWidgetToGridAndForceUpdate(widget)
      } else {
        const widget = {
          id: `widget-${guidUtils.newGuid()}`,
          type: widgetData.type,
          name: widgetData.name,
          location: {
            xAxis: widgetData.location.xAxis || 0,
            yAxis: widgetData.location.yAxis || 0
          },
          size: {
            width: widgetData.size.width,
            minimumWidth: widgetData.size.minimumWidth,
            maximumWidth: widgetData.size.maximumWidth,
            height: widgetData.size.height,
            minimumHeight: widgetData.size.minimumHeight,
            maximumHeight: widgetData.size.maximumHeight
          }
        }
        this.addWidgetToGridAndForceUpdate(widget)
      }
    },
    deleteWidget(widget) {
      if (!this.dashboardHasMatchingWidget(widget.id)) {
        return
      }
      this.removeWidgetFromGrid(widget.id)
      this.removeWidgetFromWidgetArrays(widget.id)
    },
    removeWidgetFromGrid(widgetId) {
      this.grid.removeWidget(`#${widgetId}`)
      this.grid.compact()
    },
    removeWidgetFromWidgetArrays(widgetId) {
      this.currentDashboard.dashboardWidgets = this.currentDashboard.dashboardWidgets.filter((widget) => widget.id !== widgetId)
      this.widgets = this.widgets.filter((widget) => widget.id !== widgetId)
    },
    dashboardHasMatchingWidget(widgetId) {
      return this.widgets.some((widget) => widget.id === widgetId)
    },
    async saveWidgets() {
      const widgets = []
      if (!this.grid.engine && !this.grid.engine.nodes) {
        return console.error('Unable to locate the gridstack engine or find its nodes.')
      }
      this.grid.engine.nodes.forEach((node) => {
        const widget = this.widgets.find((w) => w.id === node.id)
        if (widget) {
          widget.location = {
            xAxis: node.x,
            yAxis: node.y
          }
          widget.size = {
            width: node.w,
            minimumWidth: node.minW,
            maximumWidth: node.maxW,
            height: node.h,
            minimumHeight: node.minH,
            maximumHeight: node.maxH
          }
        }
        widgets.push(widget)
        this.currentDashboard.dashboardWidgets = widgets
      })
      await this.$store.dispatch('updateSetting', {
        settingName: 'dashboards',
        settings: this.dashboards
      })
    },
    setPageTitle() {
      this.$store.dispatch('setActiveTitle', this.currentProjectCode)
    },
    toggleEdit() {
      if (this.isEditing) {
        this.grid.disable()
        this.saveWidgets()
      } else {
        this.grid.enable()
      }
      this.isEditing = !this.isEditing
    },
    switchAutoAlign(switchValue) {
      if (switchValue) {
        this.currentDashboard.float = false
        this.grid.float(false)
        this.isAutoAligning = true
      } else {
        this.currentDashboard.float = true
        this.grid.float(true)
        this.isAutoAligning = false
      }
    },
    async toggleAddDashboardPopup() {
      let settings = await this.$refs.addDashboardPopup.open({
        title: this.$t('addDashboardPopup.mainTitle'),
        buttonLabel: this.$t('addDashboardPopup.Button')
      })
      if (settings) {
        const dashboardId = Math.max(...this.dashboards.map((dashboard) => dashboard.id)) + 1
        this.createNewDashboard(dashboardId, settings)
      }
    },
    async createNewDashboard(dashboardId, settings) {
      settings.id = dashboardId.toString()
      this.dashboards.push(settings)
      await this.$store.dispatch('updateSetting', {
        settingName: 'dashboards',
        settings: this.dashboards
      })
    },
    async getDashboardsFromService() {
      const response = await this.$store.dispatch('getSettingByName', {
        settingName: 'dashboards'
      })
      if (response.data.SettingValue === '') {
        return []
      }
      const dashboards = JSON.parse(response.data.SettingValue)
      if (Array.isArray(dashboards)) {
        return dashboards
      } else {
        return []
      }
    },
    async toggleDeleteDashboardPopup() {
      let settings = await this.$refs.deleteDashboardPopup.open({
        title: this.$t('deleteDashboardPopup.mainTitle'),
        buttonLabel: this.$t('deleteDashboardPopup.Button')
      })
      if (settings) {
        this.deleteDashboard(settings)
      }
    },
    async toggleDeleteAllWidgetsPopup() {
      let settings = await this.$refs.deleteAllWidgetsPopup.open({
        title: this.$t('deleteWidgetsPopup.mainTitle'),
        buttonLabel: this.$t('deleteWidgetsPopup.Button')
      })
      if (settings) {
        this.deleteWidgets(settings)
      }
    },
    async deleteWidgets() {
      this.currentDashboard.dashboardWidgets = []
      this.widgets = []
      this.grid.removeAll()
      this.grid.compact()

      await this.$store.dispatch('updateSetting', {
        settingName: 'dashboards',
        settings: this.dashboards
      })
    },
    async deleteDashboard(dashboard) {
      if (!this.dashboardsHasMatchingDashboard(dashboard.id)) {
        return
      }
      this.removeDashboardFromDashboardArray(dashboard.id)
      if (this.currentDashboardId === dashboard.id) {
        this.$router.push({
          name: 'dashboard',
          params: { dashboardId: '1' }
        })
      }
      await this.$store.dispatch('updateSetting', {
        settingName: 'dashboards',
        settings: this.dashboards
      })
    },
    async toggleRenameDashboardPopup() {
      let settings = await this.$refs.renameDashboardPopup.open({
        title: this.$t('renameDashboardPopup.mainTitle'),
        buttonLabel: this.$t('renameDashboardPopup.button')
      })
      if (settings) {
        this.renameDashboard(settings)
      }
    },
    async renameDashboard(settings) {
      const dashboardIndex = this.dashboards.findIndex((dashboard) => dashboard.id === settings.id)
      this.dashboards[dashboardIndex].name = settings.name
      await this.$store.dispatch('updateSetting', {
        settingName: 'dashboards',
        settings: this.dashboards
      })
    },
    dashboardsHasMatchingDashboard(dashboardId) {
      return this.dashboards.some((dash) => dash.id === dashboardId)
    },
    removeDashboardFromDashboardArray(dashboardId) {
      this.dashboards = this.dashboards.filter((dashboard) => dashboard.id !== dashboardId)
    },
    toggleGridOnWidgetPopup() {
      bus.$on('toggleWidgetMovement', (popupVisibility) => {
        this.isCustomizingWidget = popupVisibility
        if (this.isCustomizingWidget) {
          this.grid.disable()
        } else {
          this.grid.enable()
        }
        this.isCustomizingWidget = !this.isCustomizingWidget
      })
    },
    registerWidgetInterface(widgetInterface) {
      this.widgetInterfaces.push(widgetInterface)
    },
    unregisterWidgetInterface(widgetInterfaceKey) {
      this.widgetInterfaces = this.widgetInterfaces.filter((widgetInterface) => widgetInterface.key !== widgetInterfaceKey)
    },
    refreshWidgets() {
      this.widgetInterfaces.forEach((widgetInterface) => widgetInterface.refresh())
    }
  },
  computed: {
    currentGridStackContainerId() {
      return `grid-stack-container-${this.currentDashboardId}`
    },
    currentProjectId() {
      return this.$route.params.projectId
    },
    currentDashboardId() {
      return this.$route.params.dashboardId
    },
    currentDashboard() {
      return this.dashboards.find((dashboard) => dashboard.id === this.currentDashboardId)
    },
    currentProjectCode() {
      return this.$store.getters.getCurrentProject.PrCode
    },
    currentDashboardExists() {
      return this.dashboards.some((dashboard) => dashboard.id === this.currentDashboardId)
    }
  }
}
</script>

<style lang="less" scoped>
.dashboard {
  display: flex;
  height: 100%;

  .dashboard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .dashboard-grid {
    overflow: auto;
    height: 100%;

    &.grid-editing {
      margin-right: 40px;
    }

    .dashboard-defaultMessage {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .grid-stack {
      height: 100%;
      overflow: hidden;
      position: relative;

      .dashboard-grid-background {
        background: #e1e1e1;
        background-size: 100px 100px, 100/12% + 0px;
        background-image: linear-gradient(#fff 8px, transparent 8px), linear-gradient(90deg, #fff 8px, transparent 8px);
        background-position: 0px -4px, -4px 0px;
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    h1 {
      display: flex;
      justify-content: center;
      margin-top: 15rem;
      font-size: 30px;
    }

    button {
      align-items: center;
      font-size: 16px;
      border-radius: 3px;
      border: none;
      height: 50px;
      background-color: #67ac45;
      color: #ffffff;
      transition: 0.25s;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

      &:hover {
        transform: scale(0.9);
      }
    }

    div {
      flex: 1 0 50%;
    }
  }
}
.side-navigator {
  height: 100%;
  overflow: hidden;
  background-color: #515151;
  width: 22rem;
  padding: 10px 5px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .aside-header {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .create-button {
    background: none;
    border: none;
    transition: 0.25s;

    &:hover {
      transform: scale(0.95);
    }
  }
  .delete-button {
    background: none;
    border: none;
    transition: 0.25s;

    &:hover {
      transform: scale(0.95);
    }
  }
}

.buttons {
  top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 10px;
}

h3 {
  margin: 0;
  font-size: 20px;
}
.dashboard-button {
  white-space: nowrap;
  width: 100%;
  border: 0;
  padding: 5px 15px;
  text-align: left;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 2px solid transparent;
  margin-bottom: 8px;
  transition: 0.2s;
  text-decoration: none;

  &:hover {
    transform: scale(0.95);
  }

  &.blue {
    background-color: #337ab7;
    color: #fff;
  }

  &.green {
    background-color: #67ac45;
    color: #fff;
  }

  &.active {
    font-weight: bold;
    border-color: #ffffff;
  }
}
</style>
