<template>
  <div class="project-info-widget">
    <div v-if="isFetchingData" class="loader-wrap">
      <div class="loader"></div>
      <p>{{ $t('other.loading') }}..</p>
    </div>
    <div v-if="projectInfo" class="project-info-inner">
      <h2 class="widget-title">{{ $t('tableTitles.ProjectDetails') }}</h2>
      <div>
        <span class="fieldTitle">{{ $t('columnTitles.ProjectName') }}</span>
        <span>{{ projectInfo.projectName }}</span>
      </div>
      <div>
        <span class="fieldTitle">{{ $t('columnTitles.ProjectCode') }}</span>
        <span>{{ projectInfo.projectCode }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'projectInfoWidget',
  data: function () {
    return {
      widgetIsCustomizable: false,
      projectInfo: null,
      isFetchingData: true,
      codeLists: null
    }
  },
  async created() {
    this.codeLists = await this.getProjectTypeCodeList()
    this.getProjectInformation()
    this.emitWidgetIsCustomizable()
  },
  methods: {
    getProjectTypeCodeList() {
      return this.$store.dispatch('getCodeListByCategoryIDOrCode', { categoryId: 22 })
    },
    findProjectTypeByCode(PrTypeCode) {
      if (!PrTypeCode) return this.$t('message.unknown')
      return this.codeLists.find(({ GcCode }) => GcCode === PrTypeCode).GcDescription
    },
    async getProjectInformation() {
      this.isFetchingData = true
      await this.$store.dispatch('fetchProject', {
        PrID: this.$route.params.projectId
      })
      const projectData = this.$store.state.currentProject
      this.projectInfo = {
        projectName: projectData.PrName,
        projectCode: projectData.PrCode,
        projectType: this.findProjectTypeByCode(projectData.PrTypeCode)
      }
      this.isFetchingData = false
    },
    emitWidgetIsCustomizable() {
      this.$emit('widgetIsCustomizable', this.widgetIsCustomizable)
    }
  }
}
</script>

<style scoped lang="less">
.project-info-widget {
  width: 100%;
  display: flex;
  cursor: default;
  flex-direction: column;
  text-align: left;
  padding: 10px;

  h2.widget-title {
    margin: 0 0 15px 0;
    font-size: 20px;
    padding-bottom: 15px;
    color: #89b368;
    border-bottom: 1px solid #d5d5d5;
  }
  .project-info-inner {
    padding: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;

    div {
      margin: 0 0 10px 0;
      display: flex;
      flex-direction: column;
    }

    span.fieldTitle {
      font-weight: bold;
    }
  }
}
.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #f5f2f0;
  border-top: 2px solid #89b368;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
