<template>
  <div class="lab-assignments-widget">
    <div>
      <allLabAssignmentsOverview/>
    </div>
  </div>
</template>
<script>
import allLabAssignmentsOverview from '@/components/table-overviews/allLabAssignmentsOverview'

export default {
  name: 'labAssignmentsInfoWidget',
  components: {
    allLabAssignmentsOverview
  }
}
</script>
<style scoped>
.lab-assignments-widget >>> .dx-toolbar {
  background-color: #ffffff;
}
.lab-assignments-widget >>> .dx-datagrid-header-panel {
  background-color: #ffffff;
}
.lab-assignments-widget {
  height: 100%;
  width: 100%;
}
</style>
