import translations from '@/configurations/app/config-locales'
export { default as waterSamplesWidget } from '@/components/dashboards/Widgets/waterSamplesWidget'
export { default as projectInfoWidget } from '@/components/dashboards/Widgets/projectInfoWidget'
export { default as measurementPointMapWidget } from '@/components/dashboards/Widgets/measurementPointMapWidget'
export { default as groundWaterInfoWidget } from '@/components/dashboards/Widgets/groundWaterInfoWidget'
export { default as concernedContactsWidget } from '@/components/dashboards/Widgets/concernedContactsWidget'
export { default as projectParticipantsWidget } from '@/components/dashboards/Widgets/projectParticipantsWidget'
export { default as todoListWidget } from '@/components/dashboards/Widgets/todoListWidget'
export { default as locationVisitsWidget } from '@/components/dashboards/Widgets/locationVisitsWidget'
export { default as groundAirInfoWidget } from '@/components/dashboards/Widgets/groundAirInfoWidget'
export { default as soilInfoWidget } from '@/components/dashboards/Widgets/soilInfoWidget'
export { default as surfaceSampleInfoWidget } from '@/components/dashboards/Widgets/surfaceSampleInfoWidget'
export { default as labAssignmentsInfoWidget } from '@/components/dashboards/Widgets/labAssignmentsInfoWidget'
const getDate = new Date().getUTCFullYear()
const widgetsTemplate = [
  {
    id: 'waterSamplesWidget',
    type: 'waterSamplesWidget',
    name: translations.t('widgetMenu.waterSamplesWidget.name'),
    description: translations.t('widgetMenu.waterSamplesWidget.description'),
    image: require('@/components/dashboards/images/bar-chart.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 6,
      minimumWidth: 6,
      maximumWidth: 12,
      height: 5,
      minimumHeight: 5,
      maximumHeight: 5
    },
    additionalSettings: {
      graphFilter: {
        filterType: 'SamplesPerMonth',
        value: getDate
      },
      graphType: {
        type: 'bar'
      }
    }
  },
  {
    id: 'projectInfoWidget',
    type: 'projectInfoWidget',
    name: translations.t('widgetMenu.projectInfoWidget.name'),
    description: translations.t('widgetMenu.projectInfoWidget.description'),
    image: require('@/components/dashboards/images/project-info.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 2,
      minimumWidth: 2,
      maximumWidth: 3,
      height: 3,
      minimumHeight: 3,
      maximumHeight: 3
    }
  },
  {
    id: 'concernedContactsWidget',
    type: 'concernedContactsWidget',
    name: translations.t('widgetMenu.concernedContactsWidget.name'),
    description: translations.t('widgetMenu.concernedContactsWidget.description'),
    image: require('@/components/dashboards/images/concerned-contacts.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 6,
      minimumWidth: 6,
      maximumWidth: 12,
      height: 3,
      minimumHeight: 3,
      maximumHeight: 3
    }
  },
  {
    id: 'soilInfoWidget',
    type: 'soilInfoWidget',
    name: translations.t('widgetMenu.soilInfoWidget.name'),
    description: translations.t('widgetMenu.soilInfoWidget.description'),
    image: require('@/components/dashboards/images/table-icon.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 6,
      minimumWidth: 6,
      maximumWidth: 12,
      height: 6,
      minimumHeight: 6,
      maximumHeight: 8
    }
  },
  {
    id: 'projectParticipantsWidget',
    type: 'projectParticipantsWidget',
    name: translations.t('widgetMenu.projectParticipantsWidget.name'),
    description: translations.t('widgetMenu.projectParticipantsWidget.description'),
    image: require('@/components/dashboards/images/project-participants.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 6,
      minimumWidth: 6,
      maximumWidth: 12,
      height: 3,
      minimumHeight: 3,
      maximumHeight: 3
    }
  },
  {
    id: 'groundWaterInfoWidget',
    type: 'groundWaterInfoWidget',
    name: translations.t('widgetMenu.groundWaterInfoWidget.name'),
    description: translations.t('widgetMenu.groundWaterInfoWidget.description'),
    image: require('@/components/dashboards/images/table-icon.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 6,
      minimumWidth: 6,
      maximumWidth: 12,
      height: 6,
      minimumHeight: 6,
      maximumHeight: 8
    }
  },
  {
    id: 'measurementPointMapWidget',
    type: 'measurementPointMapWidget',
    name: translations.t('widgetMenu.measurementPointMapWidget.name'),
    description: translations.t('widgetMenu.measurementPointMapWidget.description'),
    image: require('@/components/dashboards/images/map-info.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 6,
      minimumWidth: 6,
      maximumWidth: 6,
      height: 6,
      minimumHeight: 6,
      maximumHeight: 6
    }
  },
  {
    id: 'groundAirInfoWidget',
    type: 'groundAirInfoWidget',
    name: translations.t('widgetMenu.groundAirInfoWidget.name'),
    description: translations.t('widgetMenu.groundAirInfoWidget.description'),
    image: require('@/components/dashboards/images/table-icon.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 6,
      minimumWidth: 6,
      maximumWidth: 12,
      height: 6,
      minimumHeight: 6,
      maximumHeight: 8
    }
  },
  {
    id: 'todoListWidget',
    type: 'todoListWidget',
    name: translations.t('widgetMenu.todoListWidget.name'),
    description: translations.t('widgetMenu.todoListWidget.description'),
    image: require('@/components/dashboards/images/todo-list.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 3,
      minimumWidth: 3,
      maximumWidth: 6,
      height: 5,
      minimumHeight: 5,
      maximumHeight: 5
    },
    additionalSettings: {
      todoList: []
    }
  },
  {
    id: 'locationVisitsWidget',
    type: 'locationVisitsWidget',
    name: translations.t('widgetMenu.locationVisitsWidget.name'),
    description: translations.t('widgetMenu.locationVisitsWidget.description'),
    image: require('@/components/dashboards/images/location-visits.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 9,
      minimumWidth: 9,
      maximumWidth: 12,
      height: 3,
      minimumHeight: 3,
      maximumHeight: 3
    }
  },
  {
    id: 'surfaceSampleInfoWidget',
    type: 'surfaceSampleInfoWidget',
    name: translations.t('widgetMenu.surfaceSampleInfoWidget.name'),
    description: translations.t('widgetMenu.surfaceSampleInfoWidget.description'),
    image: require('@/components/dashboards/images/table-icon.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 6,
      minimumWidth: 6,
      maximumWidth: 12,
      height: 6,
      minimumHeight: 6,
      maximumHeight: 8
    }
  },
  {
    id: 'labAssignmentsInfoWidget',
    type: 'labAssignmentsInfoWidget',
    name: translations.t('widgetMenu.labAssignmentsInfoWidget.name'),
    description: translations.t('widgetMenu.labAssignmentsInfoWidget.description'),
    image: require('@/components/dashboards/images/table-icon.png'),
    location: {
      xAxis: 0,
      yAxis: 0
    },
    size: {
      width: 12,
      minimumWidth: 6,
      maximumWidth: 12,
      height: 6,
      minimumHeight: 6,
      maximumHeight: 8
    }
  }
]
export default widgetsTemplate
