<template>
  <div class="todo-list">
    <h1 class="todoList-widget-title">{{ $t('todoList.title') }}</h1>
    <div class="item-nav">
      <ul class="item-nav_list">
        <li class="item-nav_items">
          <div class="task-count">{{ $t('todoList.tasks') }}: {{ todoList.length }}</div>
        </li>
        <li class="item-nav_items">
          <button v-show="isEditing" class="task-delete" @click="deleteAll">
            {{ $t('todoList.deleteAllTasks') }}
            <span class="glyphicon glyphicon-trash"></span>
          </button>
        </li>
        <li class="item-nav_items">
          <button v-show="isEditing" class="task-delete" @click="deleteSelected">
            {{ $t('todoList.deleteMarked') }}
            <span class="glyphicon glyphicon-trash"></span>
          </button>
        </li>
      </ul>
    </div>
    <div class="task-list_wrapper">
      <ul v-if="todoList.length !== 0" class="task-list" name="task-list">
        <li v-for="(todo, index) in todoList" :key="index" class="task-list_item">
          <div class="task-preview">
            <input v-model="todo.completed" type="checkbox" class="task-preview_button" :disabled="!isEditing" />
            <span :class="{ completed: todo.completed }" class="task-preview_name">{{ todo.text }}</span>
            <button v-show="isEditing" class="task-preview_delete" @click="deleteTodo(index)">
              <span class="glyphicon glyphicon-trash"></span>
            </button>
          </div>
        </li>
      </ul>
      <div v-else class="task-list_message">
        <p>{{ $t('todoList.listEmpty') }}</p>
        <span class="glyphicon glyphicon-ok"></span>
      </div>
    </div>
    <div v-if="isEditing" class="new-task">
      <div class="field">
        <input v-model="newTodo" class="field_input" :placeholder="$t('todoList.inputField')" type="text" maxlength="500" @input="capitalizeFirstLetter" @keyup.enter="addTodo()" />
        <button class="task-add" @click="addTodo">
          <span class="glyphicon glyphicon-plus-sign"></span>
        </button>
      </div>
    </div>
    <div v-else class="input-disabled">
      <div class="input-disabled_field">
        <p>{{ $t('todoList.editTasks') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import stringUtils from '@/utils/stringUtils'
export default {
  props: {
    widgetData: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      newTodo: '',
      todoList: []
    }
  },
  mounted() {
    this.setTodoListDataFromAdditionalSettings()
  },
  methods: {
    addTodo() {
      if (this.newTodo !== '') {
        this.todoList.push({ text: this.newTodo, completed: false })
        this.newTodo = ''
      }
      this.widgetData.additionalSettings.todoList = this.todoList
    },
    deleteTodo(index) {
      this.todoList.splice(index, 1)
    },
    deleteAll() {
      this.widgetData.additionalSettings.todoList = []
      this.todoList = []
    },
    deleteSelected() {
      this.todoList = this.todoList.filter((todo) => !todo.completed)
      this.widgetData.additionalSettings.todoList = this.todoList.filter((todo) => !todo.completed)
    },
    setTodoListDataFromAdditionalSettings() {
      if (!this.isEditing) {
        this.todoList = this.widgetData.additionalSettings.todoList
      }
    },
    capitalizeFirstLetter() {
      this.newTodo = stringUtils.capitalizeFirstLetter(this.newTodo)
    }
  }
}
</script>
<style lang="less" scoped>
.todo-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: 0.5s ease-in-out 0s;
  background-color: #ffffff;
}
.glyphicon {
  padding: 0;
}
.item-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;

  &_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0rem 0.5rem 0.5rem 0rem;
    padding-inline-start: 0px;
  }

  &_items {
    margin: 0.2rem;
  }
}
.task-count {
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  background-color: #3e82c3;
  color: #ffffff;
  border: none;
}
.task-add {
  font-size: 1.8rem;
  background-color: #ffffff;
  color: #3e82c3;
  border: none;
  transition: 0.25s;

  &:hover {
    transform: scale(0.9);
  }
}
.task-delete {
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  background-color: #67ac45;
  color: #ffffff;
  border: none;

  transition: 0.25s;

  &:hover {
    transform: scale(0.9);
  }
}
.todoList-widget-title {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;
  font-size: 24px;
  padding-bottom: 15px;
  color: #89b368;
  border-bottom: 1px solid #d5d5d5;
}
.new-task {
  padding: 0rem 1rem;
  transition: all 0.5s ease-in-out 0s;
}
.input-disabled {
  transition: all 0.5s ease-in-out 0s;

  &_field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    opacity: 50%;
    font-size: 15px;
    font-weight: normal;
    padding-top: 15px;
  }
}
.field {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: auto;
  transition: all 0.5s ease-in-out 0s;
  border: 0.1rem solid #25232365;
  border-radius: 0.5rem;
  background-color: #ffffff;

  &_input {
    width: 100%;
    border: 0;
    outline: none;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;

    &::placeholder {
      color: gray;
      font-weight: 200;
    }
  }
}
.task-list {
  display: flex;
  position: relative;
  flex-direction: column;
  list-style: none;
  padding-inline-start: 0px;

  &_wrapper {
    height: calc(7rem * 2.9);
    margin: 1rem;
    overflow-y: auto;
  }

  &_item {
    padding: 0.5rem;
  }
  &_message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: rgba(156, 152, 152, 0.342);
    font-size: 1.7em;
    line-height: 100%;
    text-align: center;
  }
}
.task-preview {
  display: flex;
  align-items: center;
  padding: 1rem;
  line-height: 1;
  user-select: none;
  box-shadow: 0 0 0.3rem rgba(#000, 0.4);

  &_button {
    margin-right: 1.5rem;
    width: 15px;
    height: 15px;
    opacity: 1;
    color: #c3bbb6;
    font: 2.4rem;
  }
  &_name {
    max-width: calc(100% - 10rem);
    transition: all 0.2s ease-in-out;
    font-size: 1.2rem;
    inline-size: auto;
    overflow-wrap: break-word;
  }
  &_delete {
    padding: 0;
    display: flex;
    margin-left: auto;
    list-style: none;
    background-color: #ffffff;
    color: #67ac45;
    font-size: 1.2rem;
    border: none;
  }
}
.completed {
  text-decoration: line-through;
}
</style>
