<template>
  <div class="graph-toolset">
    <div class="select-filter-type-field">
      <span>{{ $t('label.filter') }}</span>
      <DxSelectBox v-model="graphFilterSelectedValue" :placeholder="getCurrentFilterType" display-expr="name" value-expr="value" :data-source="dxSelectFilterOptions" />
    </div>
    <div class="graph-type-select">
      <span>{{ $t('label.graphType') }}</span>
      <DxSelectBox v-model="graphType" :placeholder="getCurrentGraphType" display-expr="name" value-expr="value" :data-source="dxSelectGraphTypeOptions" />
    </div>
  </div>
</template>
<script>
import DxSelectBox from 'devextreme-vue/select-box'

const FilterSamplesPerMonth = 'SamplesPerMonth'
const FilterSamplesPerYear = 'SamplesPerYear'
const FilterSamplesCustomYear = 'CustomYear'

export default {
  name: 'waterSamplesWidgetSettings',
  inject: ['validate', 'agree', 'cancel'],
  components: {
    DxSelectBox
  },
  props: {
    projectId: {
      type: String
    },
    samplesByMonth: {
      type: Array
    },
    allWaterSamples: {
      type: Array
    },
    widgetData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      graphSettingsData: [],
      graphType: '',
      graphTitle: this.$t('label.WaterSamples'),
      graphFilterSelectedValue: ''
    }
  },
  watch: {
    projectId: {
      handler: function () {
        this.validate(this.graphSettingsData)
      },
      immediate: true
    },
    graphType: {
      handler: function () {
        let additionalSettings = {}
        const barType = 'bar'
        const lineType = 'line'
        switch (this.graphType) {
          case 'bar':
            additionalSettings = { graphType: { type: barType } }
            break

          case 'line':
            additionalSettings = { graphType: { type: lineType } }
            break

          default:
            additionalSettings = { graphType: { type: barType } }
        }
        this.mapNewGraphAdditionalSettings(this.graphSettingsData, additionalSettings)
      },
      immediate: true
    },
    graphFilterSelectedValue: {
      handler: function () {
        let additionalSettings = { graphFilter: { filterType: '', value: null } }
        const getDate = new Date().getUTCFullYear()
        switch (this.graphFilterSelectedValue) {
          case 'CustomYear':
            additionalSettings = { graphFilter: { filterType: FilterSamplesCustomYear, value: this.graphFilterSelectedValue } }
            break

          case 'SamplesPerMonth':
            additionalSettings = { graphFilter: { filterType: FilterSamplesPerMonth, value: getDate } }
            break

          case 'SamplesPerYear':
            additionalSettings = { graphFilter: { filterType: 'SamplesPerYear' } }
            break

          default:
            additionalSettings = { graphFilter: { filterType: FilterSamplesCustomYear, value: this.graphFilterSelectedValue } }
        }
        this.mapNewGraphAdditionalSettings(this.graphSettingsData, additionalSettings)
      },
      immediate: true
    }
  },
  methods: {
    mapNewGraphAdditionalSettings(setting, newSettings) {
      if (newSettings.graphType) {
        setting.graphType = newSettings.graphType
      }
      if (newSettings.graphFilter) {
        setting.graphFilter = newSettings.graphFilter
      }
      this.graphSettingsData = setting
    },
    dxSelectBoxGraphTypeOptions() {
      const graphTypes = [
        { name: this.$t('graphTitles.barType'), value: 'bar' },
        { name: this.$t('graphTitles.lineType'), value: 'line' }
      ]
      return graphTypes
    },
    dxSelectBoxFilterOptions() {
      const samplesData = this.allWaterSamples
      const currentYear = new Date().getUTCFullYear()
      const groupedSamples = [
        { year: null, name: this.$t('graphTitles.waterSamplesPerYear'), value: FilterSamplesPerYear },
        { year: currentYear, name: this.$t('graphTitles.waterSamplesPerMonth', { 0: currentYear }), value: FilterSamplesPerMonth }
      ]

      samplesData
        .sort(({ WsDate }) => WsDate)
        .filter((s) => s.WsDate && s.FtID)
        .reverse()
        .forEach((sample) => {
          const sampleYear = new Date(sample.WsDate).getUTCFullYear()
          if (groupedSamples.find((s) => s.year === sampleYear)) return
          if (sampleYear === currentYear) return

          groupedSamples.push({ year: sampleYear, name: this.$t('graphTitles.waterSamplesPerMonth', { 0: sampleYear }), value: sampleYear })
        })

      return groupedSamples
    },
    currentGraphType() {
      const currentGraphType = this.widgetData.additionalSettings.graphType.type

      switch (currentGraphType) {
        case 'bar':
          this.graphType = currentGraphType
          break
        case 'line':
          this.graphType = currentGraphType
          break
        default:
          this.graphType = currentGraphType
      }
    },
    currentFilterType() {
      const currentGraphFilterType = this.widgetData.additionalSettings.graphFilter.filterType
      const currentGraphFilterValue = this.widgetData.additionalSettings.graphFilter.value

      switch (currentGraphFilterType) {
        case 'CustomYear':
          this.graphFilterSelectedValue = currentGraphFilterValue
          break
        case 'SamplesPerMonth':
          this.graphFilterSelectedValue = currentGraphFilterType
          break
        case 'SamplesPerYear':
          this.graphFilterSelectedValue = currentGraphFilterType
          break
        default:
          this.graphFilterSelectedValue = currentGraphFilterType
      }
    }
  },
  computed: {
    dxSelectFilterOptions() {
      return this.dxSelectBoxFilterOptions()
    },
    dxSelectGraphTypeOptions() {
      return this.dxSelectBoxGraphTypeOptions()
    },
    getStorageGraphSettings() {
      return this.widgetData
    },
    getCurrentGraphType() {
      return this.currentGraphType()
    },
    getCurrentFilterType() {
      return this.currentFilterType()
    }
  }
}
</script>
<style scoped lang="less">
.graph-type-select {
  top: 0;
  left: 24px;
  z-index: 9;
  width: 100%;
  max-width: 280px;

  span {
    font-weight: 600;
  }
}

.select-filter-type-field {
  top: 0;
  left: 24px;
  z-index: 9;
  width: 100%;
  max-width: 280px;

  span {
    font-weight: 600;
  }

  .select-data-type {
    border: none;
    background: #67ac45;
    color: white;
    padding: 5px;
    border-radius: 3px;
    display: block;
    outline: none;
  }
}

.graph-toolset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
</style>
