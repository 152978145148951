<template>
  <div class="delete-dashboard-modal">
    <p>{{ $t('deleteDashboardPopup.subTitle', { dashboardName }) }}</p>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>
<script>
export default {
  inject: ['validate', 'invalidate', 'agree', 'cancel'],
  props: {
    dashboards: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dashboard: {},
      dashboardName: '',
      errorMessage: ''
    }
  },
  watch: {
    dashboard: {
      handler: function () {
        let valid = this.validateDashboardId()
        if (valid) {
          this.validate(this.dashboard)
        } else {
          this.invalidate()
        }
      },
      immediate: true
    }
  },
  methods: {
    validateDashboardId() {
      this.dashboard = this.dashboards.find((dashboard) => dashboard.id === this.currentDashboardId)
      this.dashboardName = this.dashboard.name

      if (this.dashboard.id === '1') {
        this.invalidate()
        this.errorMessage = this.$t('deleteDashboardPopup.errorMessage')
        return false
      }

      if (!this.dashboard) {
        return false
      }

      this.errorMessage = ''
      return true
    }
  },
  computed: {
    currentDashboardId() {
      return this.$route.params.dashboardId
    }
  }
}
</script>

<style lang="less" scoped>
.delete-dashboard-modal p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 120%;
}

.error-message {
  font-size: 110%;
  color: red;
  margin-top: 5px;
}
</style>
