<template>
  <div v-if="isFetchingData" class="loader-wrap">
    <div class="loader"></div>
    <p>{{ $t('other.loading') }}..</p>
  </div>
  <div v-else class="measurement-point-map">
    <h2 class="map-widget-title">{{ $t('measurementPointMap.title') }}</h2>
    <openLayers :showSearchBar="false" :showActionButtons="false" :enableAddFeatureSelectInteraction="false" :showClickToContextMenu="false" />
  </div>
</template>

<script>
import openLayers from '@/components/openLayers'
export default {
  name: 'measurementPointMapWidget',
  components: {
    openLayers
  },
  data() {
    return {
      isFetchingData: true
    }
  },
  async created() {
    await this.$store.dispatch('fetchMeasurementPoints', {
      PrID: this.$route.params.projectId
    })
    this.isFetchingData = false
  }
}
</script>
<style lang="less" scoped>
.measurement-point-map {
  width: 100%;
  height: 550px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.map-widget-title {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 0 15px 0;
  font-size: 24px;
  padding-bottom: 15px;
  color: #89b368;
  border-bottom: 1px solid #d5d5d5;
}
.openLayersContainer {
  border: solid black 1px;
}

/deep/ #layerHandler {
  position: absolute;
  z-index: 1;
  bottom: 40px;
  left: 60px;
  background-color: #eee;
  transition: 0.3s;
  width: 600px;
  height: 50%;
}
.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #f5f2f0;
  border-top: 2px solid #89b368;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
