<template>
  <div class="enter-dashboard-name-field">
    <div v-if="currentDashboardIsDefaultDashboard">
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
    <div v-else>
      <p>{{ $t('renameDashboardPopup.subTitle') }}</p>
      <input v-model="dashboardName" :placeholder="currentDashboard.name" required type="text" maxlength="20" display-expr="name" value-expr="value" @input="capitalizeFirstLetter" />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </div>
</template>
<script>
import stringUtils from '@/utils/stringUtils'
export default {
  name: 'renameDashboard',
  inject: ['validate', 'invalidate', 'agree', 'cancel'],
  props: {
    dashboards: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dashboardName: '',
      errorMessage: '',
      defaultDashboardId: '1'
    }
  },
  computed: {
    currentDashboardId() {
      return this.$route.params.dashboardId
    },
    currentDashboard() {
      return this.dashboards.find((dashboard) => dashboard.id === this.currentDashboardId)
    },
    currentDashboardIsDefaultDashboard() {
      return this.currentDashboard.id === this.defaultDashboardId
    }
  },
  watch: {
    dashboardName: {
      handler: function (value) {
        let valid = this.validateDashboardName(value)
        if (valid) {
          this.validate({ name: value, class: 'blue', id: this.currentDashboardId })
        } else {
          this.invalidate()
        }
      },
      immediate: true
    }
  },
  methods: {
    validateDashboardName(value) {
      const dashboardExist = this.dashboards.some((dashboard) => dashboard.name.trim().toLowerCase() === value.trim().toLowerCase())
      this.errorMessage = ''
      if (dashboardExist) {
        this.errorMessage = this.$t('renameDashboardPopup.errorMessages.dashboardNameAlreadyExists')
        return false
      }

      if (this.currentDashboardId === '1') {
        this.errorMessage = this.$t('renameDashboardPopup.errorMessages.dashboardNameCantBeChanged')
        return false
      }

      if (value.length > 0) {
        return true
      }

      return false
    },
    capitalizeFirstLetter() {
      this.dashboardName = stringUtils.capitalizeFirstLetter(this.dashboardName)
    }
  }
}
</script>
<style lang="less" scoped>
.enter-dashboard-name-field p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 120%;
}

.error-message {
  font-size: 110%;
  color: red;
  margin-top: 5px;
}
</style>
