<template>
  <div class="concerned-contacts-widget">
    <div class="concerned-contacts-widget-container">
      <concernedContactsTable
        :projectId="projectId"
        :enableCreateTableRowAction="enableCreateTableRowAction"
        :enableDeleteTableRowAction="enableDeleteTableRowAction"
        :enableUpdateTableRowAction="enableUpdateTableRowAction"
        :searchPanelEnabled="searchPanelEnabled"
        :enableExportSettings="enableExportSettings"
      />
    </div>
  </div>
</template>
<script>
import concernedContactsTable from '@/components/dynamicTables/tables/concernedContactsTable'

export default {
  name: 'concernedContacts',
  components: {
    concernedContactsTable
  },
  data() {
    return {
      enableCreateTableRowAction: false,
      enableDeleteTableRowAction: false,
      enableUpdateTableRowAction: false,
      searchPanelEnabled: false,
      enableExportSettings: false
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    }
  }
}
</script>
<style scoped>
.concerned-contacts-widget >>> h2 {
  margin-top: 5px;
  font-size: 20px;
}
.concerned-contacts-widget {
  height: 100%;
  width: 100%;
}
.concerned-contacts-widget .concerned-contacts-widget-container {
  height: 100%;
  width: 100%;
  display: flex;
}
.concerned-contacts-widget .concerned-contacts-widget-container {
  overflow: hidden;
}
.concerned-contacts-widget .concerned-contacts-widget-container >>> div.ti-dynamic-table {
  height: 100%;
  width: 100%;
}
</style>
