<template>
  <div class="delete-dashboard-modal">
    <p>{{$t('deleteWidgetsPopup.subTitle')}}?</p>
  </div>
</template>
<script>
export default {
  inject: ['validate', 'invalidate', 'agree', 'cancel'],
  props: {
    widgets: {
      type: Array,
      required: true
    }
  },
  watch: {
    widgets: {
      handler: function () {
        let valid = this.validateWidgets()
        if (valid) {
          this.validate(this.widgets)
        } else {
          this.invalidate()
        }
      },
      immediate: true
    }
  },
  methods: {
    validateWidgets() {
      if (this.widgets.length > 0) {
        return true
      }
    }
  },
  computed: {
    currentDashboardId() {
      return this.$route.params.dashboardId
    }
  }
}
</script>

<style lang="less" scoped>
.delete-dashboard-modal p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 120%;
}
</style>
