<template>
  <div class="location-visits-widget">
    <div class="location-visits-widget-container">
      <locationVisitsTable
        :projectId="projectId"
        :enableCreateTableRowAction="enableCreateTableRowAction"
        :enableDeleteTableRowAction="enableDeleteTableRowAction"
        :enableUpdateTableRowAction="enableUpdateTableRowAction"
        :searchPanelEnabled="searchPanelEnabled"
        :enableExportSettings="enableExportSettings"
        :enableColumnChooser="enableColumnChooser"
      />
    </div>
  </div>
</template>
<script>
import locationVisitsTable from '@/components/dynamicTables/tables/locationVisitsTable'

export default {
  name: 'locationVisitsWidget',
  components: {
    locationVisitsTable
  },
  data() {
    return {
      enableCreateTableRowAction: false,
      enableDeleteTableRowAction: false,
      enableUpdateTableRowAction: false,
      searchPanelEnabled: false,
      enableExportSettings: false,
      enableColumnChooser: false
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    }
  }
}
</script>
<style scoped>
.location-visits-widget >>> h2 {
  margin-top: 5px;
  font-size: 20px;
}
.location-visits-widget {
  height: 100%;
  width: 100%;
}

.location-visits-widget .location-visits-widget-container {
  height: 100%;
  width: 100%;
  display: flex;
}
.location-visits-widget .location-visits-widget-container {
  overflow: hidden;
}
.location-visits-widget .location-visits-widget-container >>> div.ti-dynamic-table {
  height: 100%;
  width: 100%;
}
</style>
