<template>
  <div>
    <div class="flex-container">
      <div class="dashboard-error-text">
        <div class="not-found">
          <img class="not-found__image notfound-image" src="@/assets/svg/element-404.svg" />
          <h2>
            {{ $t('BrowserWarning.DashboardNotFound') }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'dashboard404'
}
</script>

<style scoped lang="less">

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .dashboard-error-text {
    text-align: center;
    padding-top: 50px;
  }
}
</style>
