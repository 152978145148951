<template>
  <div class="widget-menu" :class="widgetMenuOpen ? 'widget-menu-open' : 'widget-menu-closed'">
    <div class="ti-row">
      <span class="widget-menu-button glyphicon glyphicon-chevron-left"
        :class="widgetMenuOpen ? 'glyphicon-chevron-right' : 'glyphicon-chevron-left'"
        @click="toggleWidgetMenu" />
      <span class="widget-menu-title">
        {{ menuTitle }}
      </span>
    </div>
    <div class="widget-menu-contents">
      <div v-for="widget in widgetsToShow" :key="widget.id" class="widget-menu-content">
        <div class="widget-menu-image">
          <img class="widget-image" :src="widget.image" :alt="widget.description" />
        </div>
        <div class="widget-menu-item">
          <span class="widget-menu-label">
            {{ widget.name }}
          </span>
          <span class="widget-menu-description">
            {{ widget.description }}
          </span>
          <button class="widget-menu-add-button" @click="addWidget(widget)">
            {{ addButtonLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import widgetsTemplates from '@/components/dashboards/Widgets'
export default {
  name: 'WidgetMenu',
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    widgets: {
      type: Array,
      required: true
    },
    disabledWidgetType: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      widgetTemplates: widgetsTemplates,
      widgetMenuOpen: true,
      localDisabledWidgetType: [...this.disabledWidgetType]
    }
  },
  watch: {
    widgets: {
      handler: function () {
        if (this.widgets.some((widget) => widget.type === 'measurementPointMapWidget')) {
          this.localDisabledWidgetType.push('measurementPointMapWidget')
        } else {
          this.localDisabledWidgetType = []
        }
      },
      immediate: true
    }
  },
  methods: {
    toggleWidgetMenu() {
      this.widgetMenuOpen = !this.widgetMenuOpen
    },
    addWidget(widgetData) {
      this.$emit('addWidget', widgetData)
    }
  },
  computed: {
    addButtonLabel() {
      return this.$t('widgetMenu.addButton')
    },
    menuTitle() {
      return this.$t('widgetMenu.menuTitle')
    },
    widgetsToShow() {
      return this.widgetTemplates.filter((widget) => !this.localDisabledWidgetType.includes(widget.id))
    }
  }
}
</script>

<style lang="less" scoped>
.widget-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 25vw;
  margin-top: 60px;
  margin-bottom: 60px;
  height: calc(100vh - 120px);
  background-color: rgb(105, 105, 105);
  right: calc(-25vw + 40px);
  top: 0;
  transition: transform 0.33s;
}

.ti-row {
  display: flex;
  position: sticky;
}

.widget-menu-title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-top: 7px;
  margin-left: 12px;
}

.widget-menu-open {
  transform: translateX(calc(-25vw + 40px));
}

.widget-menu-closed {
  transform: translateX(0);
}

.widget-menu-button {
  color: white;
  top: 5px;
  left: 5px;
  font-size: 24px;
  padding: 5px;

  &:hover {
    background-color: #fff4;
  }
}

.widget-menu-image {
  display: flex;
  align-items: center;

  .widget-image {
    width: 100px;
    height: 100px;
  }
}

.widget-menu-contents {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.widget-menu-content {
  display: flex;
  flex-direction: row;
  margin: 10px;
  margin-left: 45px;
  max-width: 400px;
  min-width: 200px;
  padding: 5px 10px 10px 5px;
  border-bottom-style: groove;
}

.widget-menu-item {
  display: flex;
  color: white;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;

  .widget-menu-label {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0px;
    color: #fff;
  }

  .widget-menu-description {
    font-weight: 14px;
    padding-bottom: 10px;
    color: #fff;
  }
}

.widget-menu-add-button {
  background-color: #67ac45;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #f3f4f6;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 16px;
  transition: 0.2s;
  width: 100%;

  &:hover {
    text-decoration: none;
    transform: scale(0.9);
  }
}
.widget-menu-add-button-inactive {
  background-color: #4d554a;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #f3f4f6;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 16px;
  transition: 0.2s;
  width: 100%;

  &:hover {
    text-decoration: none;
    transform: scale(0.9);
  }
}
</style>
