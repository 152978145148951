<template>
  <div
    :id="widgetData.id"
    :gs-id="widgetData.id"
    :gs-x="widgetData.location.xAxis"
    :gs-y="widgetData.location.yAxis"
    :gs-w="widgetData.size.width"
    :gs-min-w="widgetData.size.minimumWidth"
    :gs-max-w="widgetData.size.maximumWidth"
    :gs-h="widgetData.size.height"
    :gs-min-h="widgetData.size.minimumHeight"
    :gs-max-h="widgetData.size.maximumHeight"
  >
    <div class="grid-stack-item-content" :class="{ 'cursor-move': isEditing }">
      <components
        :is="widgetData.type"
        :key="widgetData.id"
        ref="widgetPopup"
        :widgetData="widgetData"
        :isEditing="isEditing"
        :projectId="projectId"
        @saveWidgetSettings="saveWidgetSettings"
        @widgetIsCustomizable="checkIfWidgetIsCustomizable"
        @registerWidgetInterface="registerWidgetInterface"
      />
    </div>
    <button v-if="isEditing" class="grid-delete" @click="deleteWidget">
      <span class="glyphicon glyphicon-remove"></span>
    </button>
    <button v-if="isEditing && widgetIsCustomizable" class="grid-settings" @click="showWidgetPopup">
      <span class="glyphicon glyphicon-cog"></span>
    </button>
  </div>
</template>
<script>
import {
  waterSamplesWidget,
  projectInfoWidget,
  measurementPointMapWidget,
  concernedContactsWidget,
  projectParticipantsWidget,
  groundWaterInfoWidget,
  todoListWidget,
  locationVisitsWidget,
  groundAirInfoWidget,
  soilInfoWidget,
  surfaceSampleInfoWidget,
  labAssignmentsInfoWidget
} from '@/components/dashboards/Widgets'
export default {
  components: {
    waterSamplesWidget,
    projectInfoWidget,
    measurementPointMapWidget,
    concernedContactsWidget,
    projectParticipantsWidget,
    groundWaterInfoWidget,
    todoListWidget,
    locationVisitsWidget,
    groundAirInfoWidget,
    soilInfoWidget,
    surfaceSampleInfoWidget,
    labAssignmentsInfoWidget
  },
  props: {
    widgetData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      widgetIsCustomizable: false,
      widgetInterface: null,
      widgetInterfaceKey: `${this.widgetData.id}`
    }
  },
  mounted() {
    this.emitRegisterInterface()
  },
  unmounted() {
    this.emitUnregisterInterface()
  },
  methods: {
    deleteWidget() {
      return this.$emit('delete', this.widgetData)
    },
    showWidgetPopup() {
      this.$refs.widgetPopup.showWidgetPopup()
    },
    saveWidgetSettings() {
      this.$emit('saveWidgetSettings')
    },
    registerWidgetInterface(widgetInterface) {
      this.widgetInterface = widgetInterface
    },
    emitRegisterInterface() {
      if (!this.widgetInterface) return
      this.$emit('registerWidgetInterface', {
        key: this.widgetInterfaceKey,
        refresh: () => this.refresh()
      })
    },
    emitUnregisterInterface() {
      this.$emit('unregisterWidgetInterface', this.widgetInterfaceKey)
    },
    refresh() {
      this.widgetInterface.refresh()
    },
    checkIfWidgetIsCustomizable(widgetIsCustomizable) {
      this.widgetIsCustomizable = widgetIsCustomizable
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId.toString()
    }
  }
}
</script>
<style lang="less" scoped>
.grid-delete {
  border: none;
  background: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.grid-settings {
  border: none;
  background: none;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
}

.grid-stack-item-content {
  display: flex;
  border-radius: 6px;
  border: 1px solid;
  padding: 10px;
  border-color: rgba(71, 71, 71, 0.3);
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  align-items: center;
}
</style>
